import React from 'react';
import PropTypes from 'prop-types';
import { t } from '../../../domain/services/configService';
import imageProxyService from '../../../domain/services/imageProxyService';
import { dateService } from '../../../domain/services/dateService';
import { SmartLink } from '../../SmartLink';
import { IconPlus } from '../../Icon/IconPlus';
import { ImageSimple } from '../../Image/ImageSimple';
import {
  schdlShowsAncine,
  schdlShowsAncineRequirement,
  schdlShowsCollapse,
  schdlShowsDesc,
  schdlShowsExplore,
  schdlShowsImage,
  schdlShowsInfo,
  schdlShowsPlus,
  schdlShowsTags
} from '../../Styles/schedule';

export const ScheduleShowCollapse = ({ show, open }) => (
  <div className="schdl__shows__collapse" css={schdlShowsCollapse}>
    <ul className="schdl__shows__tags" css={schdlShowsTags}>
      <li className="schdl__shows__tags--main-tag">{show.tag}</li>
      <li>{show.episode}</li>
      {show.rating !== 'nan' && <li>{show.rating}</li>}
    </ul>
    {open && show.imageSrc && (
      <div className="schdl__shows__image" css={schdlShowsImage}>
        <ImageSimple
          src={imageProxyService.getCanonicalUrl(show.imageSrc)}
          alt={show.title}
        />
      </div>
    )}
    <div className="schdl__shows__info" css={schdlShowsInfo}>
      <div className="schdl__shows__desc" css={schdlShowsDesc}>
        {show.description}
      </div>
      {show.ancine_reqs ? (
        <div className="schdl__shows__ancine" css={schdlShowsAncine}>
          <ul>
            <li>
              <span
                className="schdl__shows__ancine__requirement"
                css={schdlShowsAncineRequirement}
              >
                {`${t('Date')}: `}
              </span>
              {show.day.replace(' ', ', ')}
              {show.month}
            </li>
            <li>
              <span
                className="schdl__shows__ancine__requirement"
                css={schdlShowsAncineRequirement}
              >
                {`${t('Start Time')}: `}
              </span>
              {dateService.format(show.timestamp * 1000, 'time')}
            </li>
            <li>
              <span
                className="schdl__shows__ancine__requirement"
                css={schdlShowsAncineRequirement}
              >
                {`${t('End Time')}: `}
              </span>
              {dateService.format(show.end_timestamp * 1000, 'time')}
            </li>
            <li>
              <span
                className="schdl__shows__ancine__requirement"
                css={schdlShowsAncineRequirement}
              >
                {`${t('Age Qualification')}: `}
              </span>
              {show.ageQualification}
            </li>
            <li>
              <span
                className="schdl__shows__ancine__requirement"
                css={schdlShowsAncineRequirement}
              >
                {`${t('PG Rating')}: `}
              </span>
              <img alt={t('PG Rating')} src={show.ratingsIcon} />
            </li>
            <li>
              <span
                className="schdl__shows__ancine__requirement"
                css={schdlShowsAncineRequirement}
              >
                {`${t('Year of Production')}: `}
              </span>
              {show.productionYear}
            </li>
            <li>
              <span
                className="schdl__shows__ancine__requirement"
                css={schdlShowsAncineRequirement}
              >
                {`${t('Country of Origin')}: `}
              </span>
              {show.country}
            </li>
          </ul>
        </div>
      ) : null}
      {show.status !== '0' ? (
        <SmartLink
          className="schdl__shows__explore"
          css={schdlShowsExplore}
          to={show.link}
        >
          {t('Explore Show')}
          <span className="schdl__shows__plus" css={schdlShowsPlus}>
            <IconPlus size={10} />
          </span>
        </SmartLink>
      ) : null}
    </div>
  </div>
);

ScheduleShowCollapse.propTypes = {
  show: PropTypes.shape({
    timestamp: PropTypes.number,
    end_timestamp: PropTypes.number,
    day: PropTypes.string,
    month: PropTypes.string,
    time: PropTypes.string,
    endTime: PropTypes.string,
    tag: PropTypes.string,
    title: PropTypes.string,
    nid: PropTypes.string,
    imageSrc: PropTypes.string,
    status: PropTypes.string,
    ancine_reqs: PropTypes.bool,
    link: PropTypes.string,
    schedule_page: PropTypes.string,
    episodeTitle: PropTypes.string,
    // TODO: change /ajax/tv_schedule_by_date/ngc/schedule_YYYY-MM-DD.json to make rating always a string
    rating: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    ageQualification: PropTypes.string,
    programType: PropTypes.string,
    ratingsIcon: PropTypes.string,
    country: PropTypes.string,
    productionYear: PropTypes.string,
    episode: PropTypes.string,
    description: PropTypes.string
  }).isRequired,
  open: PropTypes.bool.isRequired
};
