import React, { useState, useEffect } from 'react';
import { conf } from '../../domain/services/configService';
import { dateService } from '../../domain/services/dateService';
import { apiClientService } from '../../domain/services/apiClientService';

import { ScheduleDayPicker } from './components/ScheduleDayPicker';
import { ScheduleShow } from './components/ScheduleShow';
import { hashService } from '../../domain/services/hashService';
import { SmartLink } from '../SmartLink';
import { IconArrowLeft } from '../Icon/IconArrowLeft';
import { IconArrowRight } from '../Icon/IconArrowRight';
import { grWrap } from '../Styles';
import {
  schdl,
  schdlBack,
  schdlChannel,
  schdlForward,
  schdlFullScheduleButton,
  schdlShowsCntr,
  schdlTitle
} from '../Styles/schedule';

export const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

const DATE_FORMAT = 'm/d/Y';

const getChannels = () => conf.tvSettings.natgeo_tv.channel_map;

const jsonCache = {};

const getDateFromParts = (year, month, day) => {
  const date = new Date(`${year}-${month}-${day}T12:00:00`);
  if (!Number.isNaN(date.getTime())) {
    return date;
  }
  return null;
};

const getNowDate = () => {
  const date = new Date();
  date.setHours(12, 0, 0, 0);
  return date;
};

const getDateFromHash = () => {
  if (hashService.get('schedule')) {
    const hashParams = hashService.get('schedule').split('/');
    try {
      return getDateFromParts(hashParams[3], hashParams[1], hashParams[2]);
    } catch (error) {
      return null;
    }
  }
  return null;
};

const getChannelFromHash = () => {
  if (hashService.get('schedule')) {
    const hashParams = hashService.get('schedule').split('/');
    return hashParams[0]
      ? getChannels().find(channel => channel.machine_name === hashParams[0])
      : null;
  }
  return null;
};

const getDefaultChannel = () => {
  return getChannels()[0];
};

const setHash = (channel, date) => {
  hashService.add(
    'schedule',
    `${channel.machine_name}/${dateService.format(date, DATE_FORMAT)}`
  );
};

export const Schedule = () => {
  if (!conf.tvSettings.natgeo_tv.channel_map) {
    return null;
  }
  const [state, setState] = useState({
    shows: [],
    openShow: null,
    isLoading: false
  });

  const onShowClickHandler = index => {
    setState({ ...state, openShow: state.openShow === index ? null : index });
  };

  const today = getNowDate();
  let currentChannel = null;
  let currentDate = null;
  const channelFromHash = getChannelFromHash();
  const dateFromHash = getDateFromHash();
  // If no date is coming from hash, set hash from state.
  if (channelFromHash && dateFromHash) {
    currentChannel = channelFromHash;
    currentDate = dateFromHash;
  } else {
    currentChannel = getDefaultChannel();
    currentDate = today;
    // Enforce all urls to have a hash, so let's navigate to a new url.
    setHash(currentChannel, currentDate);
  }

  const dayLeftLimit = dateService.getStartOfWeek(today);
  const upcomingEpisodesOffset = parseInt(conf.upcoming_episodes_offset, 10);
  const dayRightLimit = dateService.addDay(
    dateService.clone(dateService.getStartOfWeek(today)),
    upcomingEpisodesOffset > 30 ? 30 : upcomingEpisodesOffset
  );

  const yesterday = currentDate
    ? dateService.addDay(dateService.clone(currentDate), -1)
    : null;
  const tomorrow = currentDate
    ? dateService.addDay(dateService.clone(currentDate), 1)
    : null;

  const onSelectChange = e => {
    setState({ ...state, openShow: null });
    setHash(
      getChannels().find(ch => ch.machine_name === e.target.value),
      currentDate
    );
  };

  const fetchData = async url => {
    const { response: jsonShows } = await apiClientService.getJson(url);

    if (jsonShows) {
      jsonCache[url] = jsonShows;
      setState({
        ...state,
        shows: jsonShows,
        openShow: null,
        isLoading: false
      });
    }
  };

  useEffect(() => {
    if (currentChannel && currentDate) {
      const url = `/ajax/tv_schedule_by_date/${
        currentChannel.machine_name
      }/schedule_${dateService.format(currentDate, 'Y-m-d')}.json`;

      if (jsonCache[url]) {
        setState({
          ...state,
          shows: jsonCache[url],
          openShow: null,
          isLoading: false
        });
      } else {
        setState({ ...state, openShow: null, isLoading: true });
        fetchData(url);
      }
    }
  }, [currentChannel.machine_name, currentDate.getTime()]);

  return (
    <div className="schdl loaded" css={schdl}>
      <div className="gr-wrap" css={grWrap}>
        <div className="schdl__title" css={schdlTitle}>
          <h1>{conf.locale['TV Guide']}</h1>
        </div>
      </div>

      {channelFromHash && dateFromHash && (
        <>
          <ScheduleDayPicker
            channel={currentChannel}
            date={currentDate}
            dayLeftLimit={dayLeftLimit}
            dayRightLimit={dayRightLimit}
          />

          <div className="gr-wrap" css={grWrap}>
            <div
              className="schdl__full_schedule_button"
              css={schdlFullScheduleButton}
            >
              {conf.display_full_schedule_button === 1 && (
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={conf.full_schedule_button_link}
                >
                  {conf.locale['Show Full Schedule']}
                </a>
              )}
            </div>

            {currentChannel && (
              <div className="schdl__channel" css={schdlChannel}>
                <select
                  onChange={onSelectChange}
                  value={currentChannel.machine_name}
                  aria-label="Channel"
                >
                  {getChannels().map(channel => (
                    <option
                      value={channel.machine_name}
                      key={channel.machine_name}
                      aria-label={channel.channel_name}
                    >
                      {channel.channel_name}
                    </option>
                  ))}
                </select>
              </div>
            )}

            <div
              id="schdl__shows-cntr"
              className={`schdl__shows-cntr ${state.isLoading ? 'hide' : ''}`}
              css={schdlShowsCntr}
            >
              {state.shows
                .filter(show => !!show)
                .map((show, index) => (
                  <ScheduleShow
                    show={show}
                    index={index}
                    open={state.openShow === index}
                    onClickHandler={onShowClickHandler}
                    key={show.timestamp}
                  />
                ))}
            </div>

            {currentChannel &&
              currentDate &&
              (yesterday >= dayLeftLimit ? (
                <SmartLink
                  to={`${document.location.pathname}#schedule=${
                    currentChannel.machine_name
                  }/${dateService.format(yesterday, DATE_FORMAT)}`}
                  className="schdl__back"
                  css={schdlBack}
                >
                  <IconArrowLeft size={14} />
                  {conf.locale.Yesterday}
                </SmartLink>
              ) : (
                <div className="schdl__back" css={schdlBack}>
                  <IconArrowLeft size={14} />
                  {conf.locale.Yesterday}
                </div>
              ))}

            {currentChannel &&
              currentDate &&
              (tomorrow < dayRightLimit ? (
                <SmartLink
                  to={`${document.location.pathname}#schedule=${
                    currentChannel.machine_name
                  }/${dateService.format(tomorrow, DATE_FORMAT)}`}
                  className="schdl__forward"
                  css={schdlForward}
                >
                  {conf.locale.Tomorrow}
                  <IconArrowRight size={14} />
                </SmartLink>
              ) : (
                <div className="schdl__forward" css={schdlForward}>
                  {conf.locale.Tomorrow}
                  <IconArrowRight size={14} />
                </div>
              ))}
          </div>
        </>
      )}
    </div>
  );
};
