import React from 'react';
import { WithIcon } from './WithIcon';

export const IconMinus = WithIcon(
  'Minus',
  '0 0 1024 1024',
  <path
    transform="scale(1, -1) translate(0, -960)"
    d="M320.8 486.2h382.6v-76.6h-382.6v76.6z"
  />
);
