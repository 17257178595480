export const hashService = {
  get: name => {
    return hashService.getHashObject()[name];
  },

  isSet: name => {
    return Object.prototype.hasOwnProperty.call(
      hashService.getHashObject(),
      name
    );
  },

  add: (name, value) => {
    const hashObject = hashService.getHashObject();
    hashObject[name] = value;
    hashService.setHashObject(hashObject);
  },

  remove: name => {
    const hashObject = hashService.getHashObject();
    delete hashObject[name];
    hashService.setHashObject(hashObject);
  },

  setHashObject: hash => {
    if (typeof window !== 'undefined') {
      // If hash is empty, use window.history to fully remove
      // the hash sign. Using window.location.hash doesn't remove it.
      if (Object.keys(hash).length > 0) {
        window.location.hash = Object.keys(hash)
          .map(name => `${name}=${hash[name]}`)
          .join('&');
      } else {
        window.history.replaceState(null, null, ' ');
      }
    }
  },

  getHashObject: () => {
    const hash = {};

    if (typeof window !== 'undefined') {
      window.location.hash
        .substr(1)
        .split('&')
        .forEach(param => {
          if (param !== '') {
            const [key, value] = param.split('=');
            hash[key] = value;
          }
        });
    }

    return hash;
  }
};
