import React from 'react';
import PropTypes from 'prop-types';
import { imageResizerService } from '../../../domain/services/imageResizerService';
import { dateService } from '../../../domain/services/dateService';
import { ScheduleShowCollapse } from './ScheduleShowCollapse';
import { IconMinus } from '../../Icon/IconMinus';
import { IconPlus } from '../../Icon/IconPlus';
import { ImageSimple } from '../../Image/ImageSimple';
import {
  schdlShows,
  schdlShowsCont,
  schdlShowsExpand,
  schdlShowsImage,
  schdlShowsList,
  schdlShowsTags,
  schdlShowsText,
  schdlShowsTime,
  schdlShowsTitle,
  open as schdlShowsOpen
} from '../../Styles/schedule';

export const ScheduleShow = ({ show, index, open, onClickHandler }) => {
  const classes = ['schdl__shows', 'loaded', ...[open ? 'open' : null]];
  const cssStyles = [schdlShows, open ? schdlShowsOpen : null];
  if (show.rating?.length === 0) {
    // eslint-disable-next-line no-param-reassign
    show.rating = '';
  }
  return (
    <div
      onClick={() => onClickHandler(index)}
      onKeyPress={() => onClickHandler(index)}
      role="link"
      tabIndex={0}
      className={classes.join(' ')}
      css={cssStyles}
    >
      <ul>
        <li css={schdlShowsList}>
          <div className="schdl__shows__time" css={schdlShowsTime}>
            <span>{dateService.format(show.timestamp * 1000, 'time')}</span>
          </div>

          <div css={schdlShowsCont}>
            <div className="schdl__shows__text" css={schdlShowsText}>
              <ul className="schdl__shows__tags" css={schdlShowsTags}>
                <li className="schdl__shows__tags--main-tag">{show.tag}</li>
                <li>{show.episode}</li>
                {!['nan', 'None', ''].includes(show.rating) && (
                  <li>{show.rating}</li>
                )}
              </ul>
              <div className="schdl__shows__title" css={schdlShowsTitle}>
                {show.title}
                {show.programType?.toLowerCase() === 'special' ||
                show.title.toUpperCase() === show.episodeTitle.toUpperCase()
                  ? ''
                  : ` - ${show.episodeTitle}`}
              </div>
              <ScheduleShowCollapse show={show} open={open} />
            </div>
            {open && show.imageSrc && (
              <div className="schdl__shows__image" css={schdlShowsImage}>
                <ImageSimple
                  src={imageResizerService.getStyleUrl(show.imageSrc, 470)}
                  alt={show.title}
                />
              </div>
            )}
          </div>
          <div className="schdl__shows__expand" css={schdlShowsExpand}>
            <span>
              {open ? <IconMinus size={20} /> : <IconPlus size={20} />}
            </span>
          </div>
        </li>
      </ul>

      <ScheduleShowCollapse show={show} open={open} />
    </div>
  );
};

ScheduleShow.propTypes = {
  show: PropTypes.shape({
    timestamp: PropTypes.number,
    end_timestamp: PropTypes.number,
    day: PropTypes.string,
    month: PropTypes.string,
    time: PropTypes.string,
    endTime: PropTypes.string,
    tag: PropTypes.string,
    title: PropTypes.string,
    nid: PropTypes.string,
    imageSrc: PropTypes.string,
    status: PropTypes.string,
    ancine_reqs: PropTypes.bool,
    link: PropTypes.string,
    schedule_page: PropTypes.string,
    episodeTitle: PropTypes.string,
    // TODO: change /ajax/tv_schedule_by_date/ngc/schedule_YYYY-MM-DD.json to make rating always a string
    rating: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    ageQualification: PropTypes.string,
    programType: PropTypes.string,
    ratingsIcon: PropTypes.string,
    country: PropTypes.string,
    productionYear: PropTypes.string,
    episode: PropTypes.string,
    description: PropTypes.string
  }).isRequired,
  index: PropTypes.number.isRequired,
  open: PropTypes.bool.isRequired,
  onClickHandler: PropTypes.func.isRequired
};
