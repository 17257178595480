import React from 'react';
import { WithIcon } from './WithIcon';

export const IconArrowLeft = WithIcon(
  'Arrow Left',
  '0 0 1024 1024',
  <path
    transform="scale(1, -1) translate(0, -960)"
    d="M992 478h-815.8l303 304.8-42.4 42.4-377.2-379.2 377.2-375.2 42.4 42.4-306.4 304.8h819.2z"
  />
);
